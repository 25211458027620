import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";

import
  {
    Card,
    Center,
    Container,
    Spinner,
    Text,
    useDisclosure,
  } from "@chakra-ui/react";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { CardList, Heading } from "@equidefi/ui";
import InvestmentCard from "../../../components/investment/InvestmentCard";
import { useUserInvestments } from "../../../hooks/useInvestments";
import { CompletedModal } from "./CompletedModal";

const Vault = (props) => {
  useDocumentTitle("My Vault");

  const { data: user } = useCurrentUser();
  const { data: investments, isLoading, refetch } = useUserInvestments();
  const navigate = useNavigate();
  const location = useLocation();
  const completedModal = useDisclosure({
    defaultIsOpen: location?.state?.completed,
  });

  const handleModalClose = () => {
    completedModal.onClose();
    navigate(location.pathname, {
      replace: true,
      state: {}
    });
  };

  useEffect(() => {
    if (location.state?.completed) refetch();
  }, [location.state]);

  const isManualPayment = useMemo(() => {
    if (!location.state?.investment_id) return false;

    const completedInvestment = investments?.find(
      (investment) => investment.id === location?.state?.investment_id
    );

    const manualPayment = completedInvestment?.payments?.find(
      (payment) => payment.category === "MANUAL"
    );

    return manualPayment;
  }, [investments, location?.state?.investment_id]);

  const investmentCards = investments?.map((investment) => (
    <InvestmentCard
      key={investment.id}
      investment={investment}
      cardAction={() => navigate(`/vault/investment/${investment.id}`)}
    />
  ));

  if (isLoading)
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );

  return (
    <Container maxW="full" p="4">
      {/* <InvestorBanner user={user} investments={investments} /> */}
      <Card p="4" mt="3">
        <Heading fontWeight="bold" textStyle="h1" mb="1.5">
          Hello{user?.first_name && `, ${user.first_name}`}
        </Heading>
        <Text>Click on an investment to view more details:</Text>

        <CardList>{investmentCards}</CardList>
      </Card>
      <CompletedModal
        modal={{
          ...completedModal,
          onClose: handleModalClose
        }}
        isManualPayment={isManualPayment}
      />
    </Container>
  );
};

export default Vault;
