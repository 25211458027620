import { FormLabelProps } from "@chakra-ui/react";
import { FC, forwardRef } from "react";
import DatePicker from "react-datepicker";
import { Input, InputProps } from "./Input";

export interface DateFieldProps extends Omit<InputProps, "onChange"> {
  name: string;
  value?: Date | null;
  onChange?: (date: Date | null) => void;
  label: string;
  helperText?: string;
  error?: string;
  labelProps?: FormLabelProps;
  isDisabled?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  showTimeSelect?: boolean;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
}

export const DateField: FC<DateFieldProps> = forwardRef(
  (
    {
      name,
      value,
      onChange,
      showTimeSelect = false,
      dateFormat,
      minDate,
      maxDate,
      placeholder = "Select date",
      ...rest
    },
    ref
  ) => {
    const defaultDateFormat = showTimeSelect
      ? "MMMM d, yyyy h:mm aa"
      : "MMMM d, yyyy";

    return (
      <Input
        as={DatePicker}
        ref={ref as any}
        name={name}
        selected={value}
        onChange={onChange}
        dateFormat={dateFormat || defaultDateFormat}
        minDate={minDate}
        maxDate={maxDate}
        showTimeSelect={showTimeSelect}
        placeholderText={placeholder}
        popperPlacement="top-start"
        sx={{
          ".react-datepicker-wrapper": {
            width: "100%",
          },
        }}
        popperModifiers={[
          {
            name: "offset",

            options: {
              zIndex: 100,
              offset: [0, 8],
            },
          },
          {
            name: "preventOverflow",
            options: {
              boundary: "viewport",
              padding: 8,
            },
          },
        ]}
        {...rest}
      />
    );
  }
);

DateField.displayName = "DateField";
