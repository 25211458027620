import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define custom variants
const equidefiVariant = definePartsStyle((props) => {
  return {
    tab: {
      _selected: {
        borderColor: "purple.400 !important",
        borderBottom: "3px solid",
      },
    },
    tablist: {
      borderBottom: "none",
    },
  };
});

const verticalTabListVariant = definePartsStyle((props) => {
  return {
    root: {
      gap: 4,
      align: "start",
    },
    tab: {
      justifyContent: "flex-start",
      px: 4,
      py: 2,
      mt: 1,
      border: "1px solid transparent",
      borderRadius: "md",
      _selected: {
        border: "1px solid",
        borderColor: "purple.200",
        borderRadius: "md",
        bg: "purple.50",
        fontWeight: "bold",
      },
      _hover: {
        border: "1px solid",
        borderColor: "purple.200",
        borderRadius: "md",
      },
    },
    tablist: {
      border: "none",
      flexDirection: "column",
    },
    tabpanel: {
      p: 2,
    },
  };
});

const variants = {
  equidefi: equidefiVariant,
  vertical: verticalTabListVariant,
};

// export the component theme
export const tabsTheme = defineMultiStyleConfig({
  variants,
});
